<template>
  <el-dialog
    :visible.sync="visible"
    :title="!dataForm.id ? $t('add') : $t('update')"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="70%"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmitHandle()"
      label-width="120px"
    >
      <el-form-item prop="name" :label="$t('role.name')">
        <el-input
          v-model="dataForm.name"
          :placeholder="$t('role.name')"
        ></el-input>
      </el-form-item>
      <el-form-item prop="remark" :label="$t('role.remark')">
        <el-input
          v-model="dataForm.remark"
          :placeholder="$t('role.remark')"
        ></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="8">
          <el-form-item size="mini" :label="$t('role.menuList')">
            <el-tree
              :data="menuList"
              :props="{ label: 'name', children: 'children' }"
              node-key="id"
              ref="menuListTree"
              accordion
              show-checkbox
            >
            </el-tree>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- <el-form-item size="mini" :label="$t('role.deptList')">
            <el-tree
              :data="deptList"
              :props="{ label: 'name', children: 'children' }"
              node-key="id"
              ref="deptListTree"
              accordion
              show-checkbox
            >
            </el-tree>
          </el-form-item> -->

          <el-form-item size="mini" label="合作方数据授权">
            <el-tree
              :data="SysappList"
              :props="{ label: 'appDesc', children: 'children' }"
              node-key="appCode"
              ref="SysappListTree"
              accordion
              show-checkbox
            >
            </el-tree>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item size="mini" label="供应商数据授权">
            <el-tree
              :data="SysappsupplierList"
              :props="{ label: 'appDesc', children: 'children' }"
              node-key="appCode"
              ref="SysappsupplierListListTree"
              accordion
              show-checkbox
            >
            </el-tree>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{
        $t('confirm')
      }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce';
export default {
  data() {
    return {
      SysappList: [],
      SysappsupplierList: [],
      visible: false,
      menuList: [],
      // deptList: [],
      dataForm: {
        id: '',
        name: '',
        menuIdList: [],
        deptIdList: [],
        remark: '',
      },
    };
  },
  computed: {
    dataRule() {
      return {
        name: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
      };
    },
  },
  methods: {
    init() {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        this.$refs.menuListTree.setCheckedKeys([]);
        // this.$refs.deptListTree.setCheckedKeys([]);
        this.$refs.SysappListTree.setCheckedKeys([]);
        this.$refs.SysappsupplierListListTree.setCheckedKeys([]);
        Promise.all([
          this.getMenuList(),
          // this.getDeptList(),
          this.getSysappsupplierList(),
          this.getSysappList(),
        ]).then(() => {
          if (this.dataForm.id) {
            this.getInfo();
          }
        });
      });
    },
    // 获取合作方列表 /sysapp/list
    getSysappList() {
      return this.$http
        .get('/sysapp/list')
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }

          console.log(res.data, '获取合作方列表');
          this.SysappList = res.data;
        })
        .catch(() => {});
    },
    // 获取供应商列表

    getSysappsupplierList() {
      return this.$http
        .get('/sysappsupplier/list')
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          console.log(res.data, '获取供应商列表');
          this.SysappsupplierList = res.data;
        })
        .catch(() => {});
    },
    // 获取菜单列表
    getMenuList() {
      return this.$http
        .get('/sys/menu/select')
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.menuList = res.data;
        })
        .catch(() => {});
    },
    // 获取部门列表
    // getDeptList() {
    //   return this.$http
    //     .get('/sys/dept/list')
    //     .then(({ data: res }) => {
    //       if (res.code !== 0) {
    //         return this.$message.error(res.msg);
    //       }
    //       this.deptList = res.data;
    //     })
    //     .catch(() => {});
    // },
    // 获取信息
    getInfo() {
      this.$http
        .get(`/sys/role/${this.dataForm.id}`)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.dataForm = {
            ...this.dataForm,
            ...res.data,
          };
          this.dataForm.menuIdList.forEach((item) =>
            this.$refs.menuListTree.setChecked(item, true)
          );

          this.dataForm.appCodeList = this.$refs.SysappListTree.setCheckedKeys(
            this.dataForm.appCodeList
          );
          this.dataForm.supplierAppCodeList =
            this.$refs.SysappsupplierListListTree.setCheckedKeys(
              this.dataForm.supplierAppCodeList
            );

          // this.$refs.deptListTree.setCheckedKeys(this.dataForm.deptIdList);
        })
        .catch(() => {});
    },
    // 表单提交
    dataFormSubmitHandle: debounce(
      function () {
        this.$refs['dataForm'].validate((valid) => {
          if (!valid) {
            return false;
          }
          this.dataForm.menuIdList = [
            ...this.$refs.menuListTree.getHalfCheckedKeys(),
            ...this.$refs.menuListTree.getCheckedKeys(),
          ];
          this.dataForm.appCodeList =
            this.$refs.SysappListTree.getCheckedKeys();
          this.dataForm.supplierAppCodeList =
            this.$refs.SysappsupplierListListTree.getCheckedKeys();
          //           this.$refs.SysappListTree.setCheckedKeys([]),
          // this.$refs.SysappsupplierListListTree.setCheckedKeys([])
          // this.dataForm.deptIdList = this.$refs.deptListTree.getCheckedKeys();
          this.$http[!this.dataForm.id ? 'post' : 'put'](
            '/sys/role',
            this.dataForm
          )
            .then(({ data: res }) => {
              if (res.code !== 0) {
                return this.$message.error(res.msg);
              }
              this.$message({
                message: this.$t('prompt.success'),
                type: 'success',
                duration: 500,
                onClose: () => {
                  this.visible = false;
                  this.$emit('refreshDataList');
                },
              });
            })
            .catch(() => {});
        });
      },
      1000,
      { leading: true, trailing: false }
    ),
  },
};
</script>
